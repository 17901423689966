import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GetStarted from "../components/sections/GetStarted"
import heroImage from "../images/img-one-click.png"
import {AlterPaymentsAnimation} from "../ui/AlterPaymentsAnimation";

const AlternativePaymentMethods = () => {
  return (
    <Layout mainbg={`co-main-dark`}>
      <SEO
        title={`Alternative payment methods - Digimeth.net`}
      />
      <article>
        <section className="black-hero typical__padding">
          <div className="site__container grid">
            <div className="left">
              <div className="content--wrapper">
                <div className="content">
                  <h2>
                    <span className="colorized">Pay the way you like</span>
                  </h2>
                  <h3 className="description white">
                    Alternative payment methods greatly improve customers' purchasing experience
                  </h3>
                </div>
              </div>
            </div>
            <div className="right">
              <picture className="fluid__wrapper">
                <img alt="hero" src={heroImage} />
              </picture>
            </div>
          </div>
        </section>

        <section className="alternative_payment-section typical__padding co-bg--white">
          <div className="site__container grid">
            <div className="left">
              <div className="content--wrapper">
                <div className="content">
                  <h2>
                    <span className="colorized">Why alternative</span>
                    <br />
                    <span className="default">payment methods?</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="right">
                <div className="green_box">
                <p className="green_box--description">
                  Alternative payment methods greatly improve customers'
                  purchasing experience. Instead of losing time, entering card
                  details, customers can use familiar and more convenient
                  alternatives. Our goal is to make the payment flow as
                  frictionless as possible and our main tools in pursuing that
                  target are alternative payment methods.
                </p>
                </div>
            </div>
          </div>
          <AlterPaymentsAnimation />
        </section>

        <section className="alternative_payment-section typical__padding co-bg--grey mt-100">

        <div className="site__container grid">
            <div className="left">
              <div className="content--wrapper">
                <div className="content">
                  <h2>
                    <span className="colorized">How to</span>
                    <span className="default"> chose?</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="right">
                <p className="green_box--description">
                Alternative payments open the world of prepaid cards, mobile payments, e-wallets, bank transfers, etc. Despite mainstream payment methods, like PayPal, merchants can also integrate more local solutions - iDEAL, Sofort etc. 
                </p>
                <br/>
                <p className="green_box--description">
The merchant's choice depends on the working area and local customers' behavioral habits. When the analysis is done, it remains the case for small - complete simple API tech integration.
                </p>
            </div>
          </div>
        </section>
        <GetStarted isGetStarted={true} title="Digimeth team is always here to help" description="to identify the right method for specific country according to defined goals"/>
      </article>
    </Layout>
  );
};

export default AlternativePaymentMethods;
