import imageKarna from "../images/alter-klarna.png";
import imageAliPay from "../images/alter-alipay.png";
import imagePayPal from "../images/alter-paypal.png";
import React, {useEffect, useRef, useState} from "react";

import "./styles.css";

const alterPaymentMethods = [
    {
        image: imageKarna,
        title: 'Klarna',
        description: "Since its foundation in 2014, Klarna has become one of the world\'s largest providers of buy now, pay later services. Ninety million active consumers use Klarna to shop online in 16 regions across three continents. Klarna accepts all major debit and credit cards such as Visa, Discover, Maestro, and Mastercard. Prepaid cards are not accepted.",
    },
    {
        image: imageAliPay,
        title: 'Alipay',
        description: "Accessible digital payments for everyone is the company`s motto. Alipay provides secure, accessible, and embedded payment solutions for businesses. The company delivers online and offline payments, marketing campaigns, and integrated industry solutions to optimize clients' business.",
    },
    {
        image: imagePayPal,
        title: 'PayPal',
        description: "PayPal has over 300 million users worldwide and provides different services: online payments, money transfers, P2P payments, BNPL services, and so on. \n" +
            "The company allows any business or individual securely, conveniently, and cost-effectively send and receive money online.",
    },
]
export const AlterPaymentsAnimation = () => {
    const [index, setIndex] = useState(0);
    const delay = 3000;
    const timeoutRef = useRef(null);

    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
            () =>
                setIndex((prevIndex) =>
                    prevIndex === alterPaymentMethods.length - 1 ? 0 : prevIndex + 1
                ),
            delay
        );

        return () => {
            resetTimeout();
        };
    }, [index]);
    return (
        <>
            {alterPaymentMethods.map(({image, title, description}, idx) => (
                <div className={`site__container grid mt-100 alter-method ${index === idx ? "active" : ""}`} key={idx}>
                    <div className="left">
                        <div className="content--wrapper">
                            <div className="content">
                                <img alt="" src={image} />
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className="description_box">
                            <h3 className="description--title">{title}</h3>
                            <p className="description--description">
                                {description}
                            </p>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
}